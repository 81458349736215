<template>
  <v-container fluid id="ObraDocumentos" class="pa-0">
    <!-- Cabeçalho do Conteudo -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
    </v-toolbar-title>

    <!-- Tabela -->
    <v-row class="mx-0">
      <!-- Grade  -->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container class="container-rounded rounded-lg mx-0 px-0">
          <!-- Conteiner ---------------------------------------->
          <v-data-table
            id="virtualScrollTable"
            ref="virtualScrollTable"
            :items="obras"
            :headers="headers"
            fixed-header
            dense
            style="white-space: nowrap, background-color: red"
            class="elevation-1"
            :height="tableHeight"
            :items-per-page="20"
            :hide-default-footer="true"
            :hide-default-header="true"
            :page="currentPage || 1"
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            :item-value="(item) => item.cod_obra"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th>
                    <v-checkbox @change="selecionarTodos($event)"></v-checkbox>
                  </th>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :class="header.class"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-for="item in items" :key="item.cod_obra">
                <!-- Linha de Etapa -->
                <tr class="color-etapa font-etapa">
                  <!-- checkbox -->
                  <td>
                    <v-checkbox
                      v-model="item.selecionado"
                      @change="selecionarObra(item, item.selecionado)"
                    ></v-checkbox>
                  </td>
                  <!-- botões editar e deletar -->
                  <td>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(opcao, i) in itemsEdicao"
                          :key="i"
                          @click="() => {}"
                          class="px-2"
                        >
                          <v-list-item-title v-if="opcao.title == 'Editar'">
                            <router-link
                              :to="'/obracontainer/' + item.cod_obra"
                              class="nav-link"
                              aria-current="page"
                            >
                              <v-icon color="green" class="mr-2 icon-menu"
                                >mdi-pencil
                              </v-icon>
                              {{ opcao.title }}
                            </router-link>
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="opcao.title == 'Excluir'"
                            ><v-icon color="red" class="mr-2 icon-menu"
                              >mdi-delete</v-icon
                            >
                            {{ opcao.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>

                  <!-- botão para expandir -->
                  <td>
                    <v-btn color="grey" icon @click="expandirItem(item)">
                      <v-icon
                        v-if="!item.expanded"
                        class="size-icon-expanded ma-0 pa-0 width-10"
                        >mdi-chevron-down</v-icon
                      >
                      <v-icon v-else class="size-icon-expanded ma-0 pa-0"
                        >mdi-chevron-up</v-icon
                      >
                    </v-btn>
                  </td>

                  <td>{{ item.cod_obra }}</td>
                  <td>{{ item.desc_obra }}</td>
                  <td>{{ item.qtnd_unid }}</td>
                  <td>{{ item.valor_unit }}</td>
                  <td>{{ item.valor_total }}</td>
                  <td>{{ item.bdi }}</td>
                  <td>{{ item.preco_unit }}</td>
                  <td>{{ item.preco_total }}</td>
                </tr>

                <!-- Linha Insumo -->
                <template v-if="item.expanded">
                  <tr
                    v-for="(insumo, index) in item.insumos"
                    :key="insumo.cod_insumo"
                  >
                    <td>
                      <v-checkbox
                        v-model="insumo.selecionado"
                        @change="
                          selecionarItem(item, insumo, insumo.selecionado)
                        "
                      ></v-checkbox>
                    </td>
                    <!-- botões editar e deletar -->
                    <td>
                      <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="grey"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list class="py-0">
                          <v-list-item
                            v-for="(opcao, i) in itemsEdicao"
                            :key="i"
                            @click="() => {}"
                            class="px-2"
                          >
                            <v-list-item-title v-if="opcao.title == 'Editar'">
                              <router-link
                                :to="'/obracontainer/' + insumo.cod_insumo"
                                class="nav-link"
                                aria-current="page"
                              >
                                <v-icon color="green" class="mr-2 icon-menu"
                                  >mdi-pencil
                                </v-icon>
                                {{ opcao.title }}
                              </router-link>
                            </v-list-item-title>

                            <v-list-item-title
                              v-else-if="opcao.title == 'Excluir'"
                              ><v-icon color="red" class="mr-2 icon-menu"
                                >mdi-delete</v-icon
                              >
                              {{ opcao.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <td>{{ item.cod_obra }}.{{ index + 1 }}</td>
                    <td>{{ insumo.cod_insumo }}</td>
                    <td>{{ insumo.desc_insumo }}</td>
                    <td>{{ insumo.qtnd_unid_insumo }}</td>
                    <td>{{ insumo.valor_unit_insumo }}</td>
                    <td>{{ insumo.valor_total_insumo }}</td>
                    <td>{{ insumo.bdi_insumo }}</td>
                    <td>{{ insumo.preco_unit_insumo }}</td>
                    <td>{{ insumo.preco_total_insumo }}</td>
                  </tr>
                  <tr>
                    <!-- botões editar e deletar da ultima linha -->
                    <td colspan="10">
                      <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="grey"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list class="py-0">
                          <v-list-item
                            v-for="(opcao, i) in itemsEdicao"
                            :key="i"
                            @click="() => {}"
                            class="px-2"
                          >
                            <v-list-item-title v-if="opcao.title == 'Editar'">
                              <router-link
                                :to="'/obracontainer/' + insumo.cod_insumo"
                                class="nav-link"
                                aria-current="page"
                              >
                                <v-icon color="green" class="mr-2 icon-menu"
                                  >mdi-pencil
                                </v-icon>
                                {{ opcao.title }}
                              </router-link>
                            </v-list-item-title>

                            <v-list-item-title
                              v-else-if="opcao.title == 'Excluir'"
                              ><v-icon color="red" class="mr-2 icon-menu"
                                >mdi-delete</v-icon
                              >
                              {{ opcao.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field> </v-text-field>
                    </td>
                  </tr>
                  <!-- Linha para preenchimento de insumo -->
                  <template> </template>
                </template>
              </tbody>
              <!-- Linha para preenchimento de etapa -->
              <tr class="color-etapa font-etapa">
                <td>
                  <v-text-field> </v-text-field>
                </td>
              </tr>

              <!-- MOBILE -->
              <!-- <tr v-if="isMobile">
                      <td style="width: auto">
                        <div
                          class="mt-1"
                          style="font-size: 14px; font-weight: 500"
                        >
                          {{ item.pessoa_nome }}
                        </div>
                        <div class="mt-1" style="font-size: 12px">
                          {{ item.pessoa_email }}
                        </div>
                        <div class="mt-1" style="font-size: 12px">
                          {{ item.fone_celular1 }}
                        </div>
                      </td>
                    </tr> -->
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <!-- Fim Grade --------------------------------- -->
    </v-row>

    <!-- Paginação -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <v-dialog
      v-model="dialog_excluir"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>

        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_excluir = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="excluir(store_Obra.pessoa_selecionado.cod_pessoa)"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{ dialog_excluir_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_excluir_alert = false"
          class="text-none"
          dark
        >
          <br />
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import store_Obra from "./store_Obra";
import store_site from "../../../store/store_site";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_BORDA,
} from "../../../services/constantes";

import {
  formatDate,
  resizeImage,
  blobToBase64,
  groupArrayMultKeys,
} from "../../../services/funcoes";

import { baseURL, API } from "../../../services/API";

export default {
  name: "ObraDocumentos",

  components: {},

  data() {
    return {
      store_Obra: store_Obra,
      store_site: store_site,
      formatDate: formatDate,
      currentPage: 1,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_BORDA: COR_BORDA,
      dialog_excluir: false,
      dialog_excluir_alert: false,
      dialog_excluir_msg: false,
      dialog_excluir_message: null,

      filtro: null,
      search: null,
      search_input_focus: false,
      arrayTipoDocumento: null,
      vencimento_visivel: null,
      dialog_ver_imagem_documento: null,
      loading_excluir: false,
      image: null,
      tipo_arquivo_aux: null,
      count_dados: null,
      paginas: null,
      expand: false,
      allSelected: false,
      selectedItems: [],

      /* Título container-rounded */
      headers: [
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "Codigo", value: "cod_obra", sortable: true },
        { text: "Descrição", value: "desc_obra", sortable: true },
        { text: "Qtnd/ Unid.", value: "qtnd_unid", sortable: true },
        { text: "Valor Unit.", value: "valor_unit", sortable: true },
        { text: "Valor Total", value: "valor_total", sortable: true },
        { text: "BDI", value: "bdi", sortable: true },
        { text: "Preço Unitario", value: "preco_unit" },
        { text: "Preço Total", value: "preco_total", sortable: true },
      ],

      obras: [
        {
          cod_obra: "1",
          desc_obra: "Serviços Preeliminares",
          valor_total: "5.213,85",
          bdi: "60%",
          preco_total: "8.432,16",
          expanded: false,
          selecionado: false,
          insumos: [
            {
              cod_insumo: "24159",
              desc_insumo: "Tijolo",
              qtnd_unid_insumo: "10",
              valor_unit_insumo: "1,00",
              valor_total_insumo: "10,00",
              bdi_insumo: "10%",
              preco_unit_insumo: "1,00",
              preco_total_insumo: "10,00",
              selecionado: false,
            },
            {
              cod_insumo: "2449",
              desc_insumo: "Cimento",
              qtnd_unid_insumo: "10",
              valor_unit_insumo: "50,00",
              valor_total_insumo: "500,00",
              bdi_insumo: "12%",
              preco_unit_insumo: "50,00",
              preco_total_insumo: "500,00",
              selecionado: false,
            },
            {
              cod_insumo: "2443",
              desc_insumo: "Areia",
              qtnd_unid_insumo: "10",
              valor_unit_insumo: "50,00",
              valor_total_insumo: "500,00",
              bdi_insumo: "12%",
              preco_unit_insumo: "50,00",
              preco_total_insumo: "500,00",
              selecionado: false,
            },
          ],
        },
        {
          cod_obra: "2",
          desc_obra: "Serviços Preeliminares",
          valor_total: "5.213,85",
          bdi: "60%",
          preco_total: "8.432,16",
          expanded: false,
          selecionado: false,

          insumos: [
            {
              cod_insumo: "245",
              desc_insumo: "Tijolo",
              qtnd_unid_insumo: "10",
              valor_unit_insumo: "1,00",
              valor_total_insumo: "10,00",
              bdi_insumo: "10%",
              preco_unit_insumo: "1,00",
              preco_total_insumo: "10,00",
              selecionado: false,
            },
          ],
        },
      ],

      /* Menu Edição (Button Dots) */
      itemsEdicao: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

      /* Formulário */
      valid: false,

      dialogNovoDocumento: false,
      dialogFile: false,

      tipo_documento: ["CNH", "RG", "CERTIDÃO DE CASAMENTO", "CPF"],

      rulesDocumentos: [(value) => !!value || "O documento é obrigatório"],

      rulesTipoDocumento: [
        (value) => !!value || "O tipo de documento é obrigatório",
      ],

      rulesVencimentoDocumento: [
        (value) => !!value || "A data do vencimento é obrigatório",
      ],
    };
  },

  mounted() {
    this.gedTipoDoc({
      tipo_cadastro: "vendas",
      tipo_cadastro_descricao: "Documentos Venda",
    }),
      this.busca({
        cod_empreend_venda: this.$route.params.cod_empreendvenda,
      });
  },

  watch: {
    search(val) {
      //console.log('WATCH', val)
      this.currentPage = 1;
    },
    allSelected(newVal) {
      this.selectAllItems(newVal);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 149 - 55;
    },
  },

  methods: {
    async forceFileDownload(fileName) {
      try {
        let nome_arquivo = fileName.split("/");

        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = nome_arquivo[nome_arquivo.length - 1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({
          err,
        });
      }
    },

    expandirItem(item) {
      item.expanded = !item.expanded;
    },

    selecionarTodos(selecionado) {
      this.obras.forEach((obra) => {
        obra.selecionado = selecionado;
        obra.insumos.forEach((item) => {
          item.selecionado = selecionado;
        });
      });
    },
    selecionarObra(obra, selecionado) {
      obra.selecionado = selecionado;
      obra.insumos.forEach((insumo) => {
        insumo.selecionado = selecionado;
      });
    },
    selecionarItem(obra, insumo, insumoSelecionado) {
      insumo.selecionado = insumoSelecionado;
      // Verifica se todos os itens da item estão selecionados
      obra.selecionado = obra.insumos.every((insumo) => insumo.selecionado);
    },

    handlePageChange(value) {
      this.currentPage = value;
    },

    verImagem(imagem_caminho, tipo_arquivo_aux) {
      // console.log(imagem_caminho)
      this.dialog_ver_imagem_documento = true;
      this.modal_imagem_caminho = imagem_caminho;
      this.tipo_arquivo_aux = tipo_arquivo_aux.toLowerCase();
    },

    async validate() {
      this.loading_salvar = true;

      let ls_Res;
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        let ls_tipo = this.image.name.split(".");

        if (this.originalImg) {
          var ls_document_original = this.originalImg.split("base64,");
          ls_document_original =
            ls_document_original[ls_document_original.length - 1];
        }

        if (this.resizedImg) {
          var ls_document_thumb = this.resizedImg.split("base64,");

          ls_document_thumb = ls_document_thumb[ls_document_thumb.length - 1];
        } else {
          ls_document_thumb == null;
        }

        // if(!this.validadeAux){
        //   this.validadeAux = moment("01/01/2900",'DD/MM/YYYY').format()
        // }
        // else{
        //   this.validadeAux = moment(this.validadeAux,'DD/MM/YYYY').format()

        // }
        // return
        var lo_JSON = {
          cod_ged_tipo_doc: this.dados_tipo.tipo_documento.cod_ged_tipo_doc,
          cod_empreend_venda: Number(this.$route.params.cod_empreendvenda),
          tipo_documento: this.dados_tipo.tipo_documento.tipo_documento,
          tipo_arquivo: ls_tipo[ls_tipo.length - 1],
          documento_base64: ls_document_original,
          documento_miniatura_base64: ls_document_thumb,
          validade: this.validadeAux,
          tipo_cadastro: "vendas",
          situacao: "Aprovado",
        };

        ls_Res = await this.store_Ged.postGed(lo_JSON);

        if (ls_Res.message == "success") {
          this.store_site.alert_cor = "green";
          this.store_site.alert_type = "success";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result;
          this.store_site.alert = true;
        } else {
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_cor = "red";
          this.store_site.alert_type = "warning";
          this.store_site.alert_msg = ls_Res.errors;
          this.store_site.alert = true;
        }

        this.dialogNovoDocumento = false;
        this.image = null;
        ls_document_thumb = null;
        this.resizedImg = null;
        lo_JSON = {};

        this.busca({
          cod_empreend_venda: this.$route.params.cod_empreendvenda,
        });
      }
    },

    readURL(file) {
      // console.log("file:::::::::: ", file)
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = (e) => {
        this.originalImg = e.target.result;
        // console.log("originalImg:::::: ", this.originalImg)

        if (file.type.match(/image.*/)) {
          // START: preview resized
          resizeImage({
            file: file,
            maxSize: 170,
          })
            .then(async (resizedImage) => {
              this.resizedImg = await blobToBase64(resizedImage);

              //this.resizedImg = URL.createObjectURL(resizedImage);
              // console.log("this.resizedImg::::", this.resizedImg)
            })
            .catch((err) => {
              console.error(err);
            });
          // END: preview resized
        }
      };
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original
    },

    url() {
      if (!this.image) return;

      return URL.createObjectURL(this.image);
    },

    async busca(val) {
      this.dados = await this.store_Ged.getGed(val);

      if (Array.isArray(this.dados) && this.dados != "Não possui dados") {
        this.dados.map((value) => {
          value.imagem_caminho_thumb = baseURL + value.thumb_caminho;
          value.imagem_caminho =
            baseURL +
            "imagens/ged/empresa_" +
            value.cod_empresa +
            "/vendas/" +
            value.imagem_caminho;
          value.cadastro_dt = formatDate(value.cadastro_dt);
        });
        this.dados = groupArrayMultKeys(this.dados, ["tipo_documento"]);
      }
      const resp = await API.get(`venda`, { params: { ...lo_params } });
      if (resp.status == 200 && resp.data.result !== "Não possui dados") {
        this.obras = resp.data.result;
        this.count_dados = this.obras.length;
        this.paginas = Math.ceil(this.count_dados / 20);
      } else {
        this.obras = [];
      }
    },

    async gedTipoDoc(p_params) {
      const ls_Rel = await API.get(`ged_tipo_documento`, {
        params: { ...p_params },
      });

      this.arrayTipoDocumento = ls_Rel.data.result;
    },
    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },
};
</script>

<style scoped>
#ObraDocumentos {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* ---------- BARRA DE SCROLL ---------- */
#ObraDocumentos::-webkit-scrollbar {
  width: 5px;
}

#ObraDocumentos::-webkit-scrollbar-button {
  padding: 1px;
}

#ObraDocumentos::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#Documentos::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px;
  font-size: 18px;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}

.search-input,
.search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px !important;
  min-width: 400px !important;
}

@media (max-width: 1024px) {
  .search-input,
  .search-input-longo {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }

  .container_btn-header {
    margin-left: 0px !important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
    /* max-width: calc(100% - 15px)!important; */
  }
}

@media (max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
  }

  .container_btn-header .caption {
    margin-top: 16px !important;
  }
}

.select-order {
  max-width: 210px;
}

@media (max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px !important;
  }

  .select-order {
    margin-top: 12px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px !important;
  }
}

.col-wrapper {
  max-width: 196px;
}

@media (max-width: 1024px) {
  .col-wrapper {
    max-width: 30% !important;
  }
}

@media (max-width: 768px) {
  .col-wrapper {
    max-width: 45% !important;
  }
}

@media (max-width: 599px) {
  .col-wrapper {
    max-width: 46% !important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px !important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  height: 250px;
}

@media (max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media (max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  }
}

@media (max-width: 599px) {
  .document-name {
    max-width: 8ch;
  }
}

@media (max-width: 375px) {
  .document-name {
    max-width: 6ch;
  }
}

@media (max-width: 360px) {
  .document-name {
    max-width: 5ch;
  }
}

.header-class {
  background-color: var(--COR_PRINCIPAL) !important;
  color: #ffffff !important;
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2);
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible !important;
  opacity: 1 !important;
}

.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media (max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}
.color-etapa {
  background-color: var(--COR_BORDA) !important;
  color: var(--COR_SUBTITULO) !important;
}

.font-etapa {
  font-weight: bold;
}

.btn-icon:hover {
  background: #fff;
}

.icon-action {
  font-size: 30px !important;
}

@media (max-width: 599px) {
  .icon-action {
    font-size: 20px !important;
  }
}
</style>
