import Vue from "vue";

function initialState() {
    return {
        step: "",
        currentTransition: 'next',
        dados: {},
        JSON_FORM: {},
        acao: null,
        botoes: [],
        cod_opor: null,
        cod_obra: null,
        cod_orcamento: null,
        obra_selecionado: {},
        dialog: false,
        dialog_consulta_deposito: false,
        dialog_cadastrar_endereco: false,
        vencimento_dt: null,
        vencimento_dt_horas: null,
        key_valor: 1
    }
}

var vm_store_ModalObra = new Vue({
    data() {
        return initialState();
    },

    methods: {

        resetData() {
            Object.assign(this.$data, initialState());
        },
    }

})

export default vm_store_ModalObra