<template>
  <v-container
    id="ModalDepositoCabecalho" class="pa-0 ma-0" v-bind:style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-container class="mb-0 pa-3 pl-5" style="width: 100%; color: #fff">
      Consulta de Depositos
    </v-container>
    <!-- Botões de ação cabeçalho -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="d-flex align-center justify-center w-100 pb-4 px-2 pt-2" 
      tabindex="-1"
      dark
      elevation="0"
    >
      <v-text-field
        id="txtBuscar"
        ref="txtBuscar"
        v-on:keyup="filtro = $event.target.value"
        prepend-inner-icon="mdi-magnify"
        autofocus
        dense
        v-model="search"
        hide-details="auto"
        cache-items
        class="search-input d-flex mr-2 ml-2"
        v-bind:class="{ 'search-input-longo': search_input_focus }"
        flat
        dark
        solo-inverted
        clearable
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        tabindex="-1"
      >
      </v-text-field>

      <v-btn
        @click="dialog_selecao = true"
        class="btn-new text-none"
        :color="COR_PRINCIPAL"
        elevation="0"
      >
        <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
        <span style="color: #fff">Novo</span>
      </v-btn>
    </v-toolbar-title>
    <v-footer
      absolute
      class="pa-2 mt-0 justify-center button-container w-100 d-flex"
    >
      <v-btn
        v-if="ls_Anterior"
        color="primary"
        class="mr-2"
        v-on:click="
          store_ModalObra.currentTransition = 'prev';
          store_ModalObra.step = ls_Anterior;
        "
        outlined
      >
        <v-icon left> mdi-arrow-left </v-icon>
        Anterior
      </v-btn>

      <v-btn
        color="primary"
        class="ml-2"
        :disabled="
          !store_ModalObra.pessoa_selecionado ||
          store_ModalObra.pessoa_selecionado.length <= 0
        "
        v-on:click="Proximo()"
        :loading="loading"
      >
        <v-icon left> mdi-arrow-right </v-icon>
        Próximo
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script type="text/javascript">
import store_ModalObra from "./store_ModalObra";
import store_usuario from "../../../../store/store_usuario";
import { formatNumber } from "../../../../services/funcoes";
import { baseURL } from "../../../../services/API";
import { COR_PRINCIPAL } from "../../../../services/constantes";

export default {
  name: "ModalDepositoCabecalho",

  data() {
    return {
      formatNumber: formatNumber,
      store_usuario: store_usuario,
      store_ModalObra: store_ModalObra,
      COR_PRINCIPAL: COR_PRINCIPAL,

      search                  : null,
      dialog_selecao          : false,

      selected                : [],
      checked                 : false,
      search_input_focus      : false,
    };
  },

  computed: {},

  mounted() {
    //console.log("Cabecalho", this.store_ModalObra.dialog);
  },

  methods: {
    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    Proximo() {
      this.store_ModalObra.currentTransition = "next";
      this.store_ModalObra.step = this.ls_Proximo;
    },
  },
};
</script>

<style scoped>
.title-value {
  font-size: 15px !important;
  color: #fff;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  background-color: #a1c6fa;
}

.icon-help {
  font-size: 1.4rem !important;
  color: #fff;
  opacity: 0.8;
}

.modal-title {
  font-size: 16px !important;
}

.sub-title {
  font-size: 14px !important;
  color: #a1c6fa;
  margin-left: 0px;
}

.ti-value {
  font-size: 15px !important;
  color: #fff;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}
</style>
