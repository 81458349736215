import Vue from "vue";
import { API } from "../../../services/API"
// import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    obra_edicao: {},  // Obra Selecionada para Edição

    currentPage: 1,
    count_dados: null,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_obra: null,
      cno: null,
      nome_obra: null,
      obra_endereco: null,
      quadra: null,
      lote: null,
      empreitada: null,
      valor_previsto_min: null,
      valor_previsto_max: null,
      valor_realizado_min: null,
      valor_realizado_max: null,
      dt_previsao_inicio: null,
      dt_previsao_termino: null,
      dt_realizacao_inicio: null,
      dt_realizacao_termino: null,
      situacao: null,
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-outline', 'Dados', '0'],
      ['mdi-cash-multiple', 'Orçamento', '0'],
      ['mdi-timer-sand-complete', 'Cronograma', '0'],
      ['mdi-tooltip-text-outline', 'Proposta', '0'],
      ['mdi-folder-multiple-plus-outline', 'Arquivos', '0'],
      ['mdi-text-box-multiple-outline', 'Relatorios', '0'],
    ],
  }
}

var vm_store_Obra = new Vue({

  data() {
    return initialState();
  },

  methods: {

    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Get de todas as obras
    async ObrasGet(p_params) {
      console.log('p_params', p_params)
      const resposta = await API.get("/obras", { params: { ...p_params } });
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result
          console.log(this.dados)
          this.count_dados = resposta.data.result.length;
          return resposta.data.result
        }
      }
    },

    // Get de uma obra específica
    async ObraGet(p_params) {
      const resposta = await API.get(`/obras/${p_params.cod_obra}`);
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          // if (p_params && p_params.cod_obra) {
          this.dados = resposta.data.result
          console.log("dados", this.dados)
          return resposta.data.result
          // }
          // else {
          //   this.dados = resposta.data.result.rows;
          //   this.paginas = resposta.data.result.paginas;
          // }
        }
      }
    },
    /* 
    async PessoaDelete(body) {
      const resp = await API.delete(`/pessoa/${body.cod_pessoa}`)
      const { data } = resp;
      if (resp.status == 200)
        return data.result;
      else
        return data;
    },
    */
    // Delete de uma obra específica

    async ObraDelete(p_params) {
      const resposta = await API.delete(`/obras/${p_params.cod_obra}`);
    },


    //   async PessoaPost(body) {
    //     const resp = await API.post(`/pessoa`, JSON.stringify(body));
    //     const { data } = resp;
    //     return data;
    //   },

    //   async PessoaPut(body) {
    //     const resp = await API.put(`/pessoa/${body.cod_pessoa}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async PessoaDelete(body) {
    //     const resp = await API.delete(`/pessoa/${body.cod_pessoa}`)
    //     const { data } = resp;
    //     if (resp.status == 200) 
    //       return data.result;
    //     else
    //       return data;
    //   }

    // // Escritura
    //     async EscrituraPut(body) {
    //     const resp = await API.put(`/escritura/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },
    // // Escritura
    //     async EscrituraDelete(body) {
    //     //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
    //     const resp = await API.put(`/escritura_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //    // Autorização de escritura
    //    async AutorizacaoPut(body) {
    //     const resp = await API.put(`/autorizacao/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   // Escritura
    //   async AutorizacaoDelete(body) {
    //     //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
    //     var resp = await API.put(`/autorizacao_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   // Rescisão
    //   async RescisaoDelete(body) {
    //     //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
    //     var resp = await API.put(`/rescisao_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   // Rescrisão 
    //   async RescisaoPut(body) {
    //     const resp = await API.put(`/rescisao/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async ModeloPost(body) {
    //     const resp = await API.post(`/editor_documento`, JSON.stringify(body));
    //     const { data } = resp;
    //     return data;
    //   },

    //   async ModeloDelete(body) {
    //     const resp = await API.delete(`/editor_documento/${body.cod_editor_documento}`)
    //     const { data } = resp;
    //     if (resp.status == 200) 
    //       return data.result;
    //     else
    //       return data;
    //   },

    //   async ModeloPut(body) {
    //     const resp = await API.put(`/editor_documento/${body.cod_editor_documento}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //     // CONTRATO
    //   async ContratoPut(body) {
    //     const resp = await API.put(`/contrato/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async ContratoDelete(body) {
    //       //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
    //       var resp = await API.put(`/contrato_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
    //       const { data } = resp;
    //       return data;
    //   },

    //   async TransferenciaPost(body) {
    //     const resp = await API.post(`/transferencia`, JSON.stringify(body));
    //     const { data } = resp;
    //     return data;
    //   },

    //   async TransferenciaPut(body) {
    //     const resp = await API.put(`/transferencia/${body.cod_empreendvenda}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async TransferenciaDelete(body) {
    //     const resp = await API.delete(`/transferencia/${body.cod_empreendvendatransf_chave}`)
    //     const { data } = resp;
    //     if (resp.status == 200) 
    //       return data.result;
    //     else
    //       return data;
    //   },


  },

  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },


})

export default vm_store_Obra
