<template>
  <v-container class="pa-0" id="ModalEnderecoDeposito">
    <!-- CABECALHO ------------------------------------------------------------------- -->
    <v-container class="pa-0">
      <v-toolbar-title elevation="0" style="height: 129px">
        <ModalDepositoCabecalho class="pa-0" ref="ModalDepositoCabecalho" />
      </v-toolbar-title>
    </v-container>

    <!-- CORPO ----------------------------------------------------------------------- -->
    <v-container class="pa-0 modal-wrapper">
      <!-- obra -->
      <v-container class="pa-0 mb-16">
        <!-- direção da transição ao abrir o modal -->
        <transition
          :name="store_ModalObra.currentTransition"
          mode="out-in"
          style="overflow-x: hidden"
        >
        </transition>
        
            <v-data-table
              v-model="selected"
              :headers="headers_enderecos"
              :items="DepositoTeste"
              :items-per-page="10"
              single-select
              show-select
              fixed-header
              top= "0px"
              hide-default-footer
              hide-default-header
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
            >
              <template #item="{ item }">
                <tr>
                  <td class="td-height">
                    <v-checkbox 
                    v-on:change="seleciona($event, item)"
                    hide-details
                    class="mr-n6"
                    style="margin-top: -50%;"/>
                  </td>
                  
                  <td class="flex-column">
                    <span 
                      style="font-weight: bold; font-size: 14px;"
                      :style="{ color: COR_SUBTITULO }"
                      class="py-4">
                      {{ item.nome_deposito }}
                    </span>
                    <br/>
                    <span style="font-size: 12px; color: #808080 !important">
                      <span style="font-weight: bold ">Endereço:&nbsp;</span>
                      {{ item.endereco }} 
                    </span>
                  </td>
                  <td>
                    <v-btn
                      @click="enderecoEdicao(item)"
                      icon
                      dense>
                      <v-icon
                        color="grey" 
                        class="btn-icon">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import store_ModalObra from "./store_ModalObra";
import store_usuario from "../../../../store/store_usuario";
import ModalDepositoCabecalho from "./ModalDepositoCabecalho.vue";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
} from "../../../../services/constantes";

export default {
  name: "ModalEnderecoDeposito",

  components: {
    ModalDepositoCabecalho,
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_ModalObra: store_ModalObra,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      COR_SUBTITULO: COR_SUBTITULO,

      selected                : [],
      checked                 : false,
      search_input_focus      : false,

      headers_enderecos: [
        { text: "", value: "checkbox" },
        { text: "", value: "endereco" },
        { text: "", value: "pencil" },
      ],

      // enderecoTeste: [
      //   {
      //     cod_endereco: "1",
      //     cod_deposito: "1",
      //     cod_obra: "1",
      //     cep: "14407218",
      //     logradouro: "Avenida Alcino Teixeira da Silva",
      //     numero: "6691",
      //     bairro: "Franca Polo Clube",
      //     cidade: "Franca",
      //     uf: "SP",
      //     complemento: "Bloco C, Apto 101",
      //     tipo: "",
      //     endereco: "Rua Radialista Alfeu Stabeline, 6691 - Franca Polo Clube",
      //   },
      //   {
      //     cod_endereco: "2",
      //     cod_deposito: "2",
      //     cod_obra: "1",
      //     cep: "14407218",
      //     logradouro: "Avenida Alcino Teixeira da Silva",
      //     numero: "6691",
      //     bairro: "Franca Polo Clube",
      //     cidade: "Franca",
      //     uf: "SP",
      //     complemento: "Bloco C, Apto 101",
      //     tipo: "",
      //     endereco: "Avenida Alcino Teixeira da Silva, 950 - Jardim Tropical 2",
      //   },
      //   {
      //     cod_endereco: "1",
      //     cod_deposito: "1",
      //     cod_obra: "1",
      //     cep: "14407218",
      //     logradouro: "Avenida Alcino Teixeira da Silva",
      //     numero: "6691",
      //     bairro: "Franca Polo Clube",
      //     cidade: "Franca",
      //     uf: "SP",
      //     complemento: "Bloco C, Apto 101",
      //     tipo: "",
      //     endereco: "Avenida dos Bagres, 225 - Aeroporto 2",
      //   },
      // ],

      DepositoTeste: [
        {
          cod_deposito: "1",
          nome_deposito: "Deposito 1",
          cep: "14407145",
          endereco: "Rua Marcelo Stabeline, 664 - Jardim Francano",
          logradouro: "Rua Marcelo Stabeline",
          numero: "664",
          bairro: "Jardim Francano",
          cidade: "Franca",
          uf: "SP",
          complemento: "Esquina",
          quadra: "Quadra 1",
          lote: "Lote 1",
        },
        {
          cod_deposito: "2",
          nome_deposito: "Deposito Lima Ultilidades LTDA.",
          cep: "14407605",
          endereco: "Avenida Paulo VI, 781 - Residencial Meirelles",
          logradouro: "Avenida Paulo VI",
          numero: "781",
          bairro: "Residencial Meirelles",
          cidade: "Franca",
          uf: "SP",
          complemento: "Proximo a Escola Estadual Prof. Antonio de Oliveira Santos",
          quadra: "Quadra 2",
          lote: "Lote 5",
        }
      ]
    };
  },

  mounted() {
    // console.log("ModalObra - Dialog1111", this.store_ModalObra.dialog_novo_lead);
  },
  methods: {
   seleciona(value, item) {
      // console.log("🚀 ~ file: PessoaConsultaMobile.vue:302 ~ seleciona ~ value:", value)
      if (value) {
        // this.store_ModalObra.obra_selecionado = item;
        // this.store_Pessoa.obra_selecionado    = item;
        //console.log("this.store_ModalObra.obra_selecionado", this.store_ModalObra.obra_selecionado.obra_nome)
      }
      else {
        // this.store_ModalObra.obra_selecionado = [];
        // this.store_Pessoa.obra_selecionado    = null;
      }
    },

    enderecoEdicao(item){
      // console.log('item', item);
      // this.store_Pessoa.pessoa_edicao = {...item}; 
      // this.store_Pessoa.acao = 'E'; 
      // this.store_Pessoa.dialog_cadastro_mobile = true;
    },
  },

  created() {
    //console.log("ModalObra - CREATE Dialog");
  },

  destroyed() {
    //console.log("ModalObra - DESTROY");
  },
};
</script>

<style scoped>
/* Próximo Quadro ------------------- */
.next-leave-active {
  opacity: 0.7;
}
.next-enter-active {
  transition: 0.2s;
}
.next-enter {
  transform: translate(100%, 0);
}
.next-leave-to {
  transform: translate(-100%, 0);
}

/* Quadro Anterior ------------------ */
.prev-leave-active {
  opacity: 0.7;
}
.prev-enter-active {
  transition: 0.2s;
}
.prev-enter {
  transform: translate(-100%, 0);
}
.prev-leave-to {
  transform: translate(100%, 0);
}

.td-height {
  min-height: 60px !important;
}

.modal-wrapper {
  overflow-y: auto;
  height: calc(100vh - 245px) !important;
  max-height: 470px !important;
}

/* CELULAR */
/* @media (max-width: 599px) {
  .modal-wrapper {
    height: calc(100vh - 150px) !important;
  }

} */
</style>