import { render, staticRenderFns } from "./ObraDocumentos.vue?vue&type=template&id=147e9e3b&scoped=true"
import script from "./ObraDocumentos.vue?vue&type=script&lang=js"
export * from "./ObraDocumentos.vue?vue&type=script&lang=js"
import style0 from "./ObraDocumentos.vue?vue&type=style&index=0&id=147e9e3b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147e9e3b",
  null
  
)

export default component.exports