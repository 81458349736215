<template>
  <div id="ObraCadastro" class="pa-3">
    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>

      <v-btn
        v-if="store_Obra.acao == 'C'"
        @click="
          store_Obra.acao = 'E';
          obra_edicaoAux = {
            ...store_Obra.obra_edicao,
          };
        "
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->
    <v-dialog
      v-model="store_Obra.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >

    </v-dialog>
    <!-- CONTAINER "LABELS" -------------------------------------------------------->
    <v-form ref="form" class="mx-1 mt-3" lazy-validation>
      <v-container class="mt-2 d-flex flex-column">
        <!-- Primeira Linha -->
        <v-row no-gutter class="pt-0" style="margin-bottom: -40px">
          <v-col md="7">
            <v-text-field
              v-model="store_Obra.obra_edicao.nome_obra"
              :rules="rulesNome"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Nome da Obra"
              placeholder="Nome da Obra"
              light
              maxlength="80"
              autofocus
              filled
              outlined
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col md="3">
            <v-text-field
              v-model="store_Obra.obra_edicao.cno"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="CNO"
              placeholder="Código Nacional de Obras"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="store_Obra.obra_edicao.area_obra"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Área"
              placeholder="Área"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- segunda Linha -->
        <v-row no-gutter class="pt-0" style="margin-bottom: -40px">
          <v-col md="6">
            <v-text-field
              v-model="store_Obra.obra_edicao.nome_cliente"
              label="Cliente"
              placeholder="Nome do Cliente"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              filled
              outlined
              dense
              background-color="map-deep-merge white"
              item-text="name"
            >
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              label="Valor Previsto"
              placeholder="Valor Previsto"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              :value="formattedValorPrevisto"
              @input="updateValorPrevisto($event)"
              filled
              outlined
              dense
              background-color="map-deep-merge white"
              item-text="name"
            >
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              label="Valor Realizado"
              placeholder="Valor Realizado"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              :value="formattedValorRealizado"
              @input="updateValorRealizado($event)"
              filled
              outlined
              dense
              background-color="map-deep-merge white"
              item-text="name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Terceira Linha -->
        <v-row no-gutter class="pt-0" style="margin-bottom: -40px">
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_previsao_inicio"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Previsão de Início"
              placeholder="Data Previsão de Início"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></DatePicker>
          </v-col>
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_previsao_termino"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Previsão de Término"
              placeholder="Data Previsão de Término"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></DatePicker>
          </v-col>
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_realizacao_inicio"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Realização de Início"
              placeholder="Data Realização de Início"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></DatePicker>
          </v-col>
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_realizacao_termino"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Realização Término"
              placeholder="Data Realização Término"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            ></DatePicker>
          </v-col>
        </v-row>
        <!-- quarta Linha -->
        <v-row no-gutter class="">
          <v-col v-if="store_Obra.acao == 'C'" md="3">
            <v-text-field
              v-model="store_Obra.obra_edicao.situacao"
              :readonly="store_Obra.acao == 'C'"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Situação"
              placeholder="Situação"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            >
            </v-text-field>
          </v-col>
          <v-col v-else-if="store_Obra.acao == 'E'" md="3">
            <v-autocomplete
              :readonly="store_Obra.acao == 'C'"
              :items="situacao"
              v-model="store_Obra.obra_edicao.situacao"
              class="input-razao mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Situação"
              placeholder="Situação"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            >
            </v-autocomplete>
          </v-col>

        </v-row>
      </v-container>

      <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
        <p
          class="font-primary font-weight-bold font-weight-medium body-1"
          v-bind:style="{ color: COR_SUBTITULO }"
        >
          Endereços
        </p>
      </v-toolbar-title>

      <!-- TABELA ENDERECOS -------------------------------------------------------->
      <v-row class="px-6">
        <v-col cols="12" class="pa-0 mx-0">
          <v-data-table
            v-if="store_Obra.dados.enderecos.length > 0"
            :headers="headers_enderecos"
            :items="store_Obra.dados.enderecos"
            :items-per-page="5"
            class="elevation-1"
            flat
            hide-default-footer
            hide-default-header
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            fixed-header
          >
            <!-- Titulos da Grade Endereço -->
            <template v-slot:header v-if="!isMobile">
              <thead>
                <tr>
                  <th
                    v-for="h in headers_enderecos"
                    :key="h.value"
                    :class="h.class"
                  >
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            
             <template #item="{ item }">
              <tr>
                <td
                  style="font-weight: bold"
                  :style="{ color: COR_SUBTITULO }"
                  class="py-4"
                >
                  {{ item.tipo }}
                </td>
                <td style="width: 23%; font-size: 12px">
                  {{ item.logradouro }}
                </td>
                <td style="font-size: 12px">{{ item.numero }}</td>
                <td style="width: 12%; font-size: 12px">{{ item.bairro }}</td>
                <td style="width: 8%; font-size: 12px">{{ item.cidade }}</td>
                <td style="width: 5%; font-size: 12px">{{ item.uf }}</td>
                <td style="font-size: 12px">{{ item.cep }}</td>
                <td style="font-size: 12px">{{ item.complemento }}</td>

                <td>

                  <v-icon class="icon-action" color="primary"
                    >mdi-pencil</v-icon
                  >
                </td>
                
                <td v-if="item.tipo == 'Endereco da obra'">
                  <v-btn icon dense @click="ConsultarEnderecoDeposito()">
                    <v-icon
                      class="btn-icon"
                      color="primary"
                      >mdi-magnify</v-icon
                    >

                  </v-btn>
                </td>
                
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            v-else-if="store_Obra.dados.enderecos.length == 0"
            :headers="headers_enderecos"
            :items="enderecoPreenchimento"
            :items-per-page="5"
            class="elevation-1"
            flat
            hide-default-footer
            hide-default-header
            loading-text="Carregando...  aguarde..."
            fixed-header
          >
            <!-- Titulos da Grade Endereço -->
            <template v-slot:header v-if="!isMobile">
              <thead>
                <tr>
                  <th
                    v-for="h in headers_enderecos"
                    :key="h.value"
                    :class="h.class"
                  >
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template #item="{ item }">
              <tr>
                <td
                  style="font-weight: bold"
                  :style="{ color: COR_SUBTITULO }"
                  class="py-4"
                >
                  {{ item.tipo }}
                </td>
                <td style="width: 23%; font-size: 12px">
                  {{ item.logradouro }}
                </td>
                <td style="font-size: 12px">{{ item.numero }}</td>
                <td style="width: 12%; font-size: 12px">{{ item.bairro }}</td>
                <td style="width: 8%; font-size: 12px">{{ item.cidade }}</td>
                <td style="width: 5%; font-size: 12px">{{ item.uf }}</td>
                <td style="font-size: 12px">{{ item.cep }}</td>
                <td style="font-size: 12px">{{ item.complemento }}</td>
                <td>
                  <v-btn icon dense @click="CadastrarEndereco()">
                  <v-icon class="btn-icon" color="primary"
                    >mdi-plus</v-icon
                  >

                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-footer
        v-if="store_Obra.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>

        <v-btn
          :loading="loading"
          @click="validate()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- modal de consulta de depositos -->
    <v-container v-if="store_ModalObra.dialog_consulta_deposito">
      <v-dialog
        v-model="store_ModalObra.dialog_consulta_deposito"
        transition="dialog-bottom-transition"
        max-width="440"
      >
        <v-card>
          <ModalEnderecoDeposito />
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal de cadastro de enderecos -->
    <v-container v-if="store_ModalObra.dialog_cadastrar_endereco">
      <v-dialog
        v-model="store_ModalObra.dialog_cadastrar_endereco"
        transition="dialog-bottom-transition"
        max-width="440"

      >
        <v-card>
          <ModalCadastrarEndereco />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Obra from "./store_Obra";
import store_ModalObra from "./ModalObra/store_ModalObra";
import ModalEnderecoDeposito from "./ModalObra/ModalEnderecoDeposito.vue";
import ModalCadastrarEndereco from "./ModalObra/ModalCadastrarEndereco.vue";
import store_site from "../../../store/store_site";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";

import { API } from "../../../services/API";

import {
  formatNumber,
  maskCpfCnpj,
  formatDate,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CPFCNPJ,
  MASK_TELEFONE,
} from "../../../services/constantes";

export default {
  name: "ObraCadastro",

  components: {
    DatePicker,
    ModalEnderecoDeposito,
    ModalCadastrarEndereco,
  },

  data() {
    return {
      store_Obra: store_Obra,
      store_site: store_site,
      store_ModalObra: store_ModalObra,

      MASK_CPFCNPJ: MASK_CPFCNPJ,
      MASK_TELEFONE: MASK_TELEFONE,
      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,
      formatNumber: formatNumber,
      formatDate: formatDate,

      valid: true,
      alert: false,
      alert_msg: "",

      loading: false,
      obra_edicaoAux: null, // Dados a serem alterados da obra selecionada

      

      /* Inputs e Menus Data */
      dateObra_dt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuObra_dt: false,

      /* MENU EDIÇÃO (BUTTON DOTS) */
      opcoes: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

      situacao: [
        "Finalizada",
        "Em Andamento",
        "Parada"
      ],

      headers_enderecos: [
        { text: "Tipo", value: "tipo_endereco" },
        { text: "Logradouro", value: "logradouro" },
        { text: "Número", value: "numero" },
        { text: "Bairro", value: "bairro" },
        { text: "Cidade", value: "cidade" },
        { text: "UF", value: "uf" },
        { text: "CEP", value: "cep" },
        { text: "Complemento", value: "complemento" },
        { text: "", value: "pencil" },
        { text: "", value: "magnify" },
      ],

      enderecoTeste: [
        {
          tipo_endereco: "Endereço da Obra",
          logradouro: "Rua Radialista Alfeu Stabeline",
          numero: "6691",
          bairro: "Franca Polo Clube",
          cidade: "Franca",
          uf: "SP",
          cep: "14407218",
          complemento: "Bloco C, Apto 101",
        },
        {
          tipo_endereco: "Endereço do Deposito",
          logradouro: "Avenida Alcino Teixeira da Silva",
          numero: "950",
          bairro: "Jardim Tropical II",
          cidade: "Franca",
          uf: "SP",
          cep: "14407218",
          complemento: "Bloco C, Apto 101",
        },
        {
          tipo_endereco: "Endereço de Cobraça",
          logradouro: "Rua Irenio Grecco Lima Soares da Silva",
          numero: "8888",
          bairro: "Vila Imperador",
          cidade: "Franca",
          uf: "SP",
          cep: "14405191",
          complemento: "Universidade",
        },
      ],
      enderecoPreenchimento: [
        {
        tipo_endereco: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        complemento: "",
        }
      ],
      
    };
  },

  watch: {
    "store_Obra.obra_edicao"(val) {
      this.atualizaAbas();
    },
  },
  async mounted() {
    // Edição do cadastro -----------------------------------------
    var lo_params = {
      cod_obra: this.$route.params.cod_obra,
    };
    if (lo_params.cod_obra) {
      // this.store_Obra.obra_edicao.cod_obra = null;
      this.store_Obra.obra_edicao = {};
      this.store_Obra.acao = "C";
      var lo_Res = await this.store_Obra.ObraGet(lo_params);
    }

    if (!!lo_Res) {
      this.store_Obra.obra_edicao = { ...lo_Res };
    }
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Obra.obra_edicao?.cod_obra &&
        this.store_Obra.obra_edicao?.menu_abas
      ) {
        let abas = await this.store_Obra.menuAbas();
        // console.log(
        //   "🚀 ~ file: ObraCadastro.vue:1179 ~ atualizaAbas ~ abas:",
        //   abas
        // );
        this.store_Obra.abas = abas;
      }
    },

    ConsultarEnderecoDeposito() {
      this.store_ModalObra.step = "ModalEnderecoDeposito";
      this.store_ModalObra.dialog_consulta_deposito = true;
    },

    CadastrarEndereco(){
      this.store_ModalObra.step = "ModalCadastrarEndereco";
      this.store_ModalObra.dialog_cadastrar_endereco = true;
    },

    // Abre Modal do Endereço -------------------------

    //
    cancelar() {
      if (this.store_Obra.acao == "I") {
        this.obra_edicaoAux = null;
        // this.$router.push("empreendimentoconsulta");
      } else if (this.store_Obra.acao == "E") {
        this.store_Obra.obra_edicao = {
          ...this.obra_edicaoAux,
        };
      }
      this.store_Obra.acao = "C";
    },

    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        var lo_JSON = {
          ...this.store_Obra.obra_edicao,
          titulo_ocorrencia: "Alterações de dados básicos",
        };

        var ls_Res;
        this.loading = true;

        if (this.store_Obra.acao == "E") {
          console.log("EDICAO", lo_JSON);
          ls_Res = await this.store_Obra.ObraPut(lo_JSON);
          // Incluindo Contato ----------------
        } else {
          // console.log('INSERCAO', lo_JSON)
          ls_Res = await this.store_Obra.ObraPost(lo_JSON);
          this.$router.push("/ObraContainer/" + ls_Res.result.data.cod_obra);
        }

        if (
          ls_Res.result &&
          ls_Res.result.message &&
          ls_Res.result.message == "success"
        ) {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          // Buscando Novamente o Registro Salvo
          var lo_params = {
            cod_obra:
              this.store_Obra.obra_edicao.cod_obra ??
              ls_Res.result.data.cod_obra,
          };
          const lo_Res = await this.store_Obra.ObraGet(lo_params);
          if (!!lo_Res)
            this.store_Obra.obra_edicao = {
              ...lo_Res,
            };

          this.store_Obra.acao = "C";
          this.obra_edicaoAux = this.store_Obra.obra_edicao;
        } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
      }
    },

    fecha_alert() {
      this.alert = false;
    },
    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Obra.obra_edicao.valor_previsto = numericValue;
    },
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Obra.obra_edicao.valor_realizado = numericValue;
    },

    // validate() {
    //   this.$refs.form.validate();
    // },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Obra.obra_edicao.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Obra.obra_edicao.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
#ObraCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #PessoaCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PessoaCadastro::-webkit-scrollbar {
  width: 5px;
}

#PessoaCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#PessoaCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#PessoaCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #PessoaCadastro::-webkit-scrollbar {
    width: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}
</style>
