<template>
    <v-container id="ModalCadastrarEndereco" class="pa-0">
        <!-- TÍTULO DA PÁGINA -->
        <v-container class="pa-0">
            <v-toolbar-title elevation="0" style="height: 100px">
                Cadastro de Endereço
            </v-toolbar-title>
        </v-container>
        <!-- Corpo Modal Cadastro de Endereço -->
        <v-row>
            <v-col 
             cols="11"
             class="px-0"
            >
                <v-text-field
                v-model="store_Obra.dados.enderecos.logradouro"
                class="mb-0"
                light
                background-color="#FFF"
                label="Logradouro"
                placeholder="Logradouro"
                filled
                outlined
                required
                :rules="logradouroRules"
                dense>
                </v-text-field>

                <v-text-field
                v-model="store_Obra.dados.enderecos.numero"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Número"
                placeholder="Número"
                filled
                :rules="numeroRules"
                outlined
                required
                dense>
                </v-text-field>

                <v-text-field
                v-model="store_Obra.dados.enderecos.bairro"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Bairro"
                placeholder="Bairro"
                filled
                outlined
                :rules="bairroRules"
                required
                dense>
                </v-text-field>

                <v-text-field
                v-model="store_Obra.dados.enderecos.cidade"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Cidade"
                placeholder="Cidade"
                filled
                outlined
                required
                dense>
                </v-text-field>

                <v-text-field
                v-model="store_Obra.dados.enderecos.uf"
                class="mt-n2"
                light
                background-color="#FFF"
                label="UF"
                placeholder="UF"
                filled
                outlined
                required
                dense
                >
                </v-text-field>

                <v-text-field
                v-model="store_Obra.dados.enderecos.complemento"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Complemento"
                placeholder="Complemento"
                filled
                outlined
                required
                dense
                >
                </v-text-field>
            </v-col>
        </v-row>    
    </v-container>
</template>
<script>
import store_ModalObra from "./store_ModalObra";
import store_Obra from "./../store_Obra";
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA, MASK_CEP  } from "../../../../services/constantes";

import { API } from "../../../../services/API"

export default {
  name: "ModalCadastrarEndereco",
  components: {
    
    },

    data() {
        return {
        store_ModalObra: store_ModalObra,
        store_Obra: store_Obra,

        COR_PRINCIPAL     : COR_PRINCIPAL,
        COR_SUBTITULO     : COR_SUBTITULO,  
        COR_SECUNDARIA    : COR_SECUNDARIA,
        MASK_CEP          : MASK_CEP,

        logradouroRules: [
        //(value) => !!value || "O endereço é obrigatório",
        (value) =>
          (!value || value.length >= 3) ||
          "O Logradouro deve ter no mínimo 3 caracteres",
        (value) =>
          (!value || value.length <= 60) ||
          "O Logradouro deve ter menos do que 60 caracteres",
        ],
        numeroRules: [
            //(value) => !!value || !!this.dados_modal_endereco.pessoa_endereco_cep || "O número é obrigatório",
            //(value) => /^\d+$/.test(value) || 'O número não é válido',
            (value) =>
            (!value || value.length <= 10) ||
            "O número deve ter menos do que 10 caracteres",
        ],
        bairroRules: [
            //(value) => !!value || "O bairro é obrigatório",
            (value) =>
            (!value || value.length >= 3) ||
            "O bairro deve ter no mínimo 3 caracteres",
            (value) =>
            (!value || value.length <= 60) ||
            "O bairro deve ter menos do que 60 caracteres",
        ],
        cepRules: [
            //(value) => c || "O cep é obrigatório",
            //(value) => /^[0-9]{2}[0-9]{3}-[0-9]{3}$/.test(value) || "O cep não é válido",
            (value) =>
            (!value || value.length == 9) ||
                "O cep não é válido",
        ],
        municipioRules: [
            //(value) => 
            //(!value || this.dados_modal_endereco.pessoa_endereco_cep ||
            //  "O municipio é obrigatório"),
        ],
        }
    }
}

</script>
